.MuiTableCell-root {
  /* text-align: center; */
  word-wrap: break-word;
  white-space: normal !important;
  overflow-wrap: break-word;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTableCell-root.MuiTableCell-body {
  /* text-align: center; */
}

.MuiTableCell-head {
  /* any specific styles for headers */
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}

.full-width-table {
  width: 100%;
  min-width: 980px;
}

.mui-custom-table-contain {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.refresh-button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.refresh-button:hover {
  background-color: #0056b3;
}

.refresh-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.cursor-pointer {
  cursor: pointer;
}
