.input-custom-contain {
  margin: 5px;
}

.input-custom-contain input[type="number"]::-webkit-inner-spin-button,
.input-custom-contain input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-custom-contain input[type="number"] {
  -moz-appearance: textfield;
}

.custom-div-dashboard {
  overflow-x: hidden;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-right: 10px;
}

.input-custom-contain-smooth {
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, height 0.7s ease, visibility 0.3s;
}

.input-custom-contain-smooth.show {
  opacity: 1;
  height: auto;
  visibility: visible;
  overflow: visible;
}

/* For a bit of spacing between the card form and the data form on small screens */
@media (max-width: 767.98px) {
  /* Bootstrap's md breakpoint */
  .align-items-center > .col-md-6:not(:last-child) {
    margin-bottom: 20px;
  }
}
