/* General styling */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  color: #333; /* Darker text color for better readability */
}

.container {
  padding: 20px;
  max-width: 800px; /* Consider setting a max width for larger screens */
  margin: auto;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 2rem 265px;
  min-height: 100vh;
  box-sizing: border-box;
}

form {
  background-color: #fff;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
  padding: 20px;
}

/* Form Inputs Styling */
.form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Form Labels */
form label {
  display: block;
  margin-bottom: 0.5rem; /* Increased space for a cleaner look */
  color: #495057;
  font-weight: 500;
}

/* Error Feedback */
.invalid-feedback {
  color: red;
  font-size: 0.875rem;
}

/* Custom Select Input */
.basic-single {
  width: 100%;
}

.select__control {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

/* Buttons */
button[type="submit"] {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 1rem; /* Added space above the submit button */
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Responsiveness */
@media (max-width: 576px) {
  .container {
    padding: 1rem;
  }

  form {
    margin-top: 1rem;
  }
}
