.card-preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.form-container {
  flex: 1;
  padding: 20px;
}

.input-field {
  display: block;
  width: 100%;
  padding: 7px;
  margin-bottom: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.invalid-input {
  border: 2px solid red;
}

.error-message {
  height: 19px;
  color: red;
  font-size: 12px;
}

.input-field::placeholder {
  color: #aaa;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.container-credit {
  max-width: 800px;
  margin: auto;
  padding: 1rem;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .card-preview {
    margin-bottom: 20px;
  }
}
