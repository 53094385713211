.navbar-custom {
  position: fixed !important;
  width: calc(100% - 250px);
  left: 250px;
  flex-direction: row-reverse;
  transition: all 0.5s ease;
  border-bottom: 1px solid #dbdbdb;
  z-index: 999;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 0.3rem 1rem;
  max-height: 60px;
}

.navbar-custom .btn {
  font-size: 1.2rem;
  color: #333;
  transition: color 0.3s ease;
}

.navbar-custom .btn:hover {
  color: #007bff;
}

.navbar-custom #basic-navbar-nav .ml-auto .nav-link {
  color: #333;
  transition: color 0.3s ease;
}

.navbar-custom #basic-navbar-nav .ml-auto .nav-link:hover {
  color: #007bff;
}

.navbar-custom #basic-navbar-nav .ml-auto .nav-item.show .nav-link,
.navbar-custom #basic-navbar-nav .ml-auto .nav-item.active .nav-link {
  color: #007bff;
}

.navbar-custom #basic-navbar-nav .ml-auto .dropdown-menu {
  border: none;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

.navbar-custom #basic-navbar-nav .ml-auto .dropdown-menu .dropdown-item {
  transition: background-color 0.3s ease;
}

.navbar-custom #basic-navbar-nav .ml-auto .dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-menu-align-right .dropdown-menu {
  right: 0;
  left: auto;
}

/* Sidebar */
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 250px;
  height: 100%;
  transition: all 0.3s ease-out;
  overflow: hidden;
  background-color: #2a2f38; /* Darker shade for a more modern look */
  border-right: 1px solid #333;
  box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

/* Text styling for better readability on dark backgrounds */
.sidebar-text-content {
  white-space: nowrap;
  color: #ddd; /* Light gray for text */
}

.nav {
  transition: all 0.3s ease-out;
  width: 250px;
  overflow: hidden;
}

/* Active Icon */
.activeIcon-custom {
  color: #6eade0; /* A modern blue color */
  height: 10px;
}

/* Sidebar collapse for responsive design */
.nav.collapsed {
  width: 0;
}

/* Icon container styling remains unchanged */
.icon-container {
  width: 20px;
  text-align: center;
  color: #ddd;
}

/* Nav link styles */
.nav-link-wrapper {
  padding: 10px 20px; /* Added some horizontal padding */
  color: #bbb; /* Lighter text color for a dark background */
  transition: background-color 0.2s; /* Transition for a smoother hover effect */
}

.nav-link-wrapper:hover {
  background-color: #383e4a; /* A slightly lighter shade on hover */
  color: #fff; /* White color on hover */
}

.nav-link-wrapper.active {
  background-color: #005aa3; /* Active state has a blue background */
  color: #fff; /* White text for active state */
}

@media screen and (max-width: 1400px) {
  .nav {
    width: 0;
  }

  .navbar-custom {
    left: 0;
    width: 100%;
    flex-direction: row;
  }

  .nav.open {
    width: 250px;
  }
}

.mt-auto.mb-3.ml-3.btn-logout {
  margin: 10px;
  background-color: #494949; /* Light grey */
  color: #a5a5a5; /* Dark grey text */
  border-radius: 5px; /* Mildly rounded borders */
  border: 1px solid #636363; /* Light border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08); /* Very light shadow for depth */

  width: 100%; /* Makes the button stretch across the full width of its parent */
  display: flex; /* Turns button into a flex container */
  justify-content: center; /* Centers button content (text) */
  align-items: center; /* Aligns button content vertically */
}

.mt-auto.mb-3.ml-3.btn-logout:hover {
  background-color: #e4e4e4; /* A shade darker on hover */
  color: #8f8f8f; /* Slightly darker text */
  border-color: #c6c6c6; /* Darken the border a bit */
  text-decoration: none; /* No underline */
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1); /* Slightly darker shadow on hover */
}

/* Wrapper styles */
.user-section {
  position: absolute;
  bottom: 15px; /* adjust this value based on the distance you want from the bottom */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the children horizontally */
  padding: 0 20px; /* Left and right padding */
}

/* Username div styles */
.username {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 10px; /* spacing between the username and the logout button */
  color: #a5a5a5;
}

.fixed-topbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #494949;
  color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: left 0.3s ease-out;
}

.fixed-topbar-container.open {
  left: 250px; /* Adjust as per your sidebar width */
}

.sidebar-toggle-btn {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 24px;
}

.sidebar-text-content {
  font-weight: 500;
}

.icon-container {
  font-size: 18px;
}

.activeIcon-custom {
  color: #007bff; /* Using Bootstrap's primary color */
}
